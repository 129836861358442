const _get_user_from_localStorage = () => {
  const user_profile = localStorage.getItem("user_data");
  if (
    user_profile &&
    user_profile !== undefined &&
    user_profile !== "undefined" &&
    user_profile !== null
  ) {
    return JSON.parse(localStorage.getItem("user_data"));
  } else {
    return {};
  }
};
const get_login_info_localStorage = () => {
  return JSON.parse(localStorage.getItem("login_info"));
};
const get_login_page_localStorage = () => {
  return JSON.parse(localStorage.getItem("login_page"));
};
const _get_content_setting_localStorage = () => {
  const content = localStorage.getItem("content_setting");
  if (
    content &&
    content !== undefined &&
    content !== "undefined" &&
    content !== null
  ) {
    return JSON.parse(content);
  } else {
    return {};
  }
};
const _get_testmonial_localStorage = () => {
  return JSON.parse(localStorage.getItem("testmonial"));
};
const _get_home_event_localStorage = () => {
  return JSON.parse(localStorage.getItem("home_event"));
};
const _get_admin_time_zone_localStorage = () => {
  return JSON.parse(localStorage.getItem("admin_time_zone"));
};
const _get_editor_keys_localStorage = () => {
  return JSON.parse(localStorage.getItem("editor_keys"));
};
const get_project_info_localStorage = () => {
  return JSON.parse(localStorage.getItem("project_info"));
};
const get_js_code_localStorage = () => {
  return JSON.parse(localStorage.getItem("jsCode"));
};
const get_css_code_localStorage = () => {
  return JSON.parse(localStorage.getItem("cssCode"));
};
const get_consultant_data_localStorage = () => {
  return JSON.parse(localStorage.getItem("consultant_data"));
};
const get_client_configuration_localStorage = () => {
  return JSON.parse(localStorage.getItem("client_configuration"));
};
const get_page_content_localStorage = () => {
  return JSON.parse(localStorage.getItem("page_content"));
};
const _get_navbar_list_localStorage = () => {
  return JSON.parse(localStorage.getItem("navbar_list"));
};
const _get_is_seen_video_localStorage = () => {
  return localStorage.getItem("is_seen_video");
};

const _get_client_graphics_from_localStorage = () => {
  return localStorage.getItem("client_graphics_data");
};

const _get_client_meta_data_from_localStorage = () => {
  return JSON.parse(localStorage.getItem("client_meta_data"));
};

const set_client_graphics_in_localStorage = (data) => {
  return localStorage.setItem("client_graphics_data", JSON.stringify(data));
};
const set_client_configuration_in_localStorage = (data) => {
  return localStorage.setItem("client_configuration", JSON.stringify(data));
};

const _get_default_content_from_localStorage = () => {
  return JSON.parse(localStorage.getItem("defaultContent"));
};

const _get_defaultLangugae_from_localStorage = () => {
  return JSON.parse(localStorage.getItem("defaulLanguagae"));
};
const _get_client_navbar_content_from_localStorage = () => {
  return JSON.parse(localStorage.getItem("clientNavbarContent"));
};

module.exports = {
  _get_editor_keys_localStorage,
  get_login_info_localStorage,
  _get_user_from_localStorage,
  _get_content_setting_localStorage,
  _get_testmonial_localStorage,
  _get_home_event_localStorage,
  _get_admin_time_zone_localStorage,
  get_project_info_localStorage,
  get_consultant_data_localStorage,
  _get_navbar_list_localStorage,
  _get_is_seen_video_localStorage,
  get_page_content_localStorage,
  get_js_code_localStorage,
  get_css_code_localStorage,
  get_login_page_localStorage,
  _get_client_graphics_from_localStorage,
  set_client_graphics_in_localStorage,
  _get_client_meta_data_from_localStorage,
  _get_default_content_from_localStorage,
  _get_defaultLangugae_from_localStorage,
  _get_client_navbar_content_from_localStorage,
  set_client_configuration_in_localStorage,
  get_client_configuration_localStorage,
};
