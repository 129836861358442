import React, { useContext, useEffect, useState } from "react";
import {
  get_consultant_data_localStorage,
  get_project_info_localStorage,
  get_login_info_localStorage,
  _get_admin_time_zone_localStorage,
  _get_content_setting_localStorage,
  _get_home_event_localStorage,
  _get_navbar_list_localStorage,
  _get_testmonial_localStorage,
  _get_user_from_localStorage,
  get_login_page_localStorage,
  _get_client_graphics_from_localStorage,
  set_client_graphics_in_localStorage,
  _get_client_meta_data_from_localStorage,
  _get_default_content_from_localStorage,
  _get_defaultLangugae_from_localStorage,
  _get_client_navbar_content_from_localStorage,
  set_client_configuration_in_localStorage,
  get_client_configuration_localStorage,
} from "src/DAL/localStorage/localStorage";

import io from "socket.io-client";
import { socketBaseUri } from "src/config/config";
import { get_date_with_user_time_zone } from "src/utils/constants";
import moment from "moment";
const CreateContentSetting = React.createContext();
const content_setting = _get_content_setting_localStorage();
const get_testmonial_localStorage = _get_testmonial_localStorage();
const get_home_event_localStorage = _get_home_event_localStorage();
const get_admin_time_zone_localStorage = _get_admin_time_zone_localStorage();
const get_project_info = get_project_info_localStorage();
const get_login_info = get_login_page_localStorage();
const _get_user_info = _get_user_from_localStorage();
const _get_consultant_data = get_consultant_data_localStorage();
const client_configuration = get_client_configuration_localStorage();
const get_navbar_list = _get_navbar_list_localStorage();
const client_graphics = _get_client_graphics_from_localStorage();

const client_meta_data = _get_client_meta_data_from_localStorage();
const get_default_content = _get_default_content_from_localStorage();
const get_default_language = _get_defaultLangugae_from_localStorage();
const get_client_navbar_content =
  _get_client_navbar_content_from_localStorage();

// let socket = { off: () => {}, emit: () => {}, on: () => {} };
// socket-initialization
const socketConfig = {
  reconnectionDelayMax: 10000,
};
let socket = { on: () => {}, emit: () => {} };
// toturn off the socket
socket = io(socketBaseUri + `?user_id=${_get_user_info._id}`, socketConfig);
//----------------------
export const useContentSetting = () => useContext(CreateContentSetting);
function ContentSettingState({ children }) {
  /* ------------------------------------------------------
  ------------------| States |--------------------
  ------------------------------------------------------- */

  const [adminTimeZone, setAdminTimeZone] = useState(
    get_admin_time_zone_localStorage
  );
  const [contentSettingData, setContentSettingData] = useState(content_setting);
  const [jsCode, setJSCode] = useState();
  const [cssCode, setCssCode] = useState();
  const [notificationsCount, setNotificationsCount] = useState(0);
  const [unreadMessageCount, setUnreadMessageCount] = useState(0);
  const [feedSetting, setFeedSetting] = useState(null);
  const [stripeKey, setStripeKey] = useState("");
  const [projectInfo, setProjectInfo] = useState(get_project_info);
  const [loginInfo, setLoginInfo] = useState(get_login_info);
  const [isChangingCoach, setIsChangingCoach] = useState(false);
  const [userInfo, setUserInfo] = useState(_get_user_info);
  console.log(userInfo, "userInfouserInfouserInfo");
  const [isConnected, setIsConnected] = useState(socket.connected);
  const [consultantInfo, setConsultantInfo] = useState(_get_consultant_data);
  const [clientConfiguration, setClientConfiguration] =
    useState(client_configuration);
  const [consultantList, setConsultantList] = useState([]);

  const [navbarList, setNavbarList] = useState(get_navbar_list);

  const [dashboardEventData, setDashboardEventData] = useState(
    get_home_event_localStorage
  );
  const [dashboardTestimonialData, setDashboardTestimonialData] = useState(
    get_testmonial_localStorage
  );
  const [dashboardSettingData, setDashboardSettingData] = useState("");
  const [graphicsData, setGraphicsData] = useState(client_graphics);

  // --------------

  const [clientMetaData, setClientMetaData] = useState(client_meta_data);
  const [defaultData, setDefaultData] = useState(get_default_content);
  const [defaulLanguagae, setDefaultLanguage] = useState(
    get_default_language ? get_default_language : "English"
  );
  const [isLnaguageChange, setIsLanguageChange] = useState(false);

  const [clientNavbarContent, setClientNavbarContent] = useState(
    get_client_navbar_content
  );

  // const community = {
  //   main_community: {
  //     share_your_thoughts_card: {
  //       share_your_thoughts_placeholder: "Share your thoughts,",
  //       photos_text: "Photos",
  //       videos_text: "Videos",
  //     },
  //     create_post_card: {
  //       create_post_button_text: "Create Post",
  //       whats_on_your_mind_placeholder: "What's on your mind,",
  //       general_feed_tooltip: "General Feed",
  //       image_feed_tooltip: "Image Feed",
  //       video_feed_tooltip: "Video Feed",
  //       embeded_code_feed_tooltip: "General Feed",
  //       add_photo_placeholder: "Add Photo",
  //       video_url_placeholder: "Video Url",
  //       embeded_code_feed_placeholder: "LiveStream Embeded Code",
  //       post_button_text: "Post",
  //       posting_button_text: "Posting...",
  //       update_button_text: "Update",
  //       updating_button_text: "Updating...",
  //     },
  //     post_card: {
  //       edit_menu_text: "Edit",
  //       delete_menu_text: "Delete",
  //       report_menu_text: "Report",
  //       delete_confirmation_text: "Are you sure you want to delete this post?",
  //       report_confirmation_text: "Are you sure you want to report this post?",
  //       see_more_button_text: "See More",
  //       see_less_button_text: "See Less",
  //       like_button_text: "Like",
  //       liked_button_text: "Liked",
  //       comment_button_text: "Comment",
  //     },
  //     feed_detail: {
  //       see_more_button_text: "See More",
  //       see_less_button_text: "See Less",
  //       like_button_text: "Like",
  //       liked_button_text: "Liked",
  //       comment_button_text: "Comment",
  //       write_a_comment_placeholder: "Write a comment…",
  //       main_comment_edit_menu_text: "Edit",
  //       main_comment_like_text: "Like",
  //       main_comment_liked_text: "Liked",
  //       main_comment_reply_text: "Reply",
  //       main_comment_delete_menu_text: "Delete",
  //       main_comment_confirmation_text:
  //         "Are you sure you want to delete this comment?",
  //       write_a_reply_placeholder: "Write a reply",
  //       single_comment_edit_menu_text: "Edit",
  //       single_comment_delete_menu_text: "Delete",
  //       single_comment_confirmation_menu_text:
  //         "Are you sure you want to delete this comment?",
  //     },
  //   },
  //   programme_community: {
  //     share_your_thoughts_card: {
  //       share_your_thoughts_placeholder: "Share your thoughts,",
  //       photos_text: "Photos",
  //       videos_text: "Videos",
  //     },
  //     create_post_card: {
  //       create_post_button_text: "Create Post",
  //       whats_on_your_mind_placeholder: "What's on your mind,",
  //       general_feed_tooltip: "General Feed",
  //       image_feed_tooltip: "Image Feed",
  //       video_feed_tooltip: "Video Feed",
  //       embeded_code_feed_tooltip: "General Feed",
  //       add_photo_placeholder: "Add Photo",
  //       video_url_placeholder: "Video Url",
  //       embeded_code_feed_placeholder: "LiveStream Embeded Code",
  //       post_button_text: "Post",
  //       posting_button_text: "Posting...",
  //       update_button_text: "Update",
  //       updating_button_text: "Updating...",
  //     },
  //     post_card: {
  //       edit_menu_text: "Edit",
  //       delete_menu_text: "Delete",
  //       report_menu_text: "Report",
  //       delete_confirmation_text: "Are you sure you want to delete this post?",
  //       report_confirmation_text: "Are you sure you want to report this post?",
  //       see_more_button_text: "See More",
  //       see_less_button_text: "See Less",
  //       like_button_text: "Like",
  //       liked_button_text: "Liked",
  //       comment_button_text: "Comment",
  //     },
  //     feed_detail: {
  //       see_more_button_text: "See More",
  //       see_less_button_text: "See Less",
  //       like_button_text: "Like",
  //       liked_button_text: "Liked",
  //       comment_button_text: "Comment",
  //       write_a_comment_placeholder: "Write a comment…",
  //       main_comment_edit_menu_text: "Edit",
  //       main_comment_like_text: "Like",
  //       main_comment_liked_text: "Liked",
  //       main_comment_reply_text: "Reply",
  //       main_comment_delete_menu_text: "Delete",
  //       main_comment_confirmation_text:
  //         "Are you sure you want to delete this comment?",
  //       write_a_reply_placeholder: "Write a reply",
  //       single_comment_edit_menu_text: "Edit",
  //       single_comment_delete_menu_text: "Delete",
  //       single_comment_confirmation_menu_text:
  //         "Are you sure you want to delete this comment?",
  //     },
  //   },
  //   lesson_community: {
  //     share_your_thoughts_card: {
  //       share_your_thoughts_placeholder: "Share your thoughts,",
  //       photos_text: "Photos",
  //       videos_text: "Videos",
  //     },
  //     create_post_card: {
  //       create_post_button_text: "Create Post",
  //       whats_on_your_mind_placeholder: "What's on your mind,",
  //       general_feed_tooltip: "General Feed",
  //       image_feed_tooltip: "Image Feed",
  //       video_feed_tooltip: "Video Feed",
  //       embeded_code_feed_tooltip: "General Feed",
  //       add_photo_placeholder: "Add Photo",
  //       video_url_placeholder: "Video Url",
  //       embeded_code_feed_placeholder: "LiveStream Embeded Code",
  //       post_button_text: "Post",
  //       posting_button_text: "Posting...",
  //       update_button_text: "Update",
  //       updating_button_text: "Updating...",
  //     },
  //     post_card: {
  //       edit_menu_text: "Edit",
  //       delete_menu_text: "Delete",
  //       report_menu_text: "Report",
  //       delete_confirmation_text: "Are you sure you want to delete this post?",
  //       report_confirmation_text: "Are you sure you want to report this post?",
  //       see_more_button_text: "See More",
  //       see_less_button_text: "See Less",
  //       like_button_text: "Like",
  //       liked_button_text: "Liked",
  //       comment_button_text: "Comment",
  //     },
  //     feed_detail: {
  //       see_more_button_text: "See More",
  //       see_less_button_text: "See Less",
  //       like_button_text: "Like",
  //       liked_button_text: "Liked",
  //       comment_button_text: "Comment",
  //       write_a_comment_placeholder: "Write a comment…",
  //       main_comment_edit_menu_text: "Edit",
  //       main_comment_like_text: "Like",
  //       main_comment_liked_text: "Liked",
  //       main_comment_reply_text: "Reply",
  //       main_comment_delete_menu_text: "Delete",
  //       main_comment_confirmation_text:
  //         "Are you sure you want to delete this comment?",
  //       write_a_reply_placeholder: "Write a reply",
  //       single_comment_edit_menu_text: "Edit",
  //       single_comment_delete_menu_text: "Delete",
  //       single_comment_confirmation_menu_text:
  //         "Are you sure you want to delete this comment?",
  //     },
  //   },
  //   portal_community: {
  //     share_your_thoughts_card: {
  //       share_your_thoughts_placeholder: "Share your thoughtsssssssssss,",
  //       photos_text: "Photos",
  //       videos_text: "Videos",
  //     },
  //     create_post_card: {
  //       create_post_button_text: "Create Post",
  //       whats_on_your_mind_placeholder: "What's on your mind,",
  //       general_feed_tooltip: "General Feed",
  //       image_feed_tooltip: "Image Feed",
  //       video_feed_tooltip: "Video Feed",
  //       embeded_code_feed_tooltip: "General Feed",
  //       add_photo_placeholder: "Add Photo",
  //       video_url_placeholder: "Video Url",
  //       embeded_code_feed_placeholder: "LiveStream Embeded Code",
  //       post_button_text: "Post",
  //       posting_button_text: "Posting...",
  //       update_button_text: "Update",
  //       updating_button_text: "Updating...",
  //     },
  //     post_card: {
  //       edit_menu_text: "Edit",
  //       delete_menu_text: "Delete",
  //       report_menu_text: "Report",
  //       delete_confirmation_text: "Are you sure you want to delete this post?",
  //       report_confirmation_text: "Are you sure you want to report this post?",
  //       see_more_button_text: "See More",
  //       see_less_button_text: "See Less",
  //       like_button_text: "Like",
  //       liked_button_text: "Liked",
  //       comment_button_text: "Comment",
  //     },
  //     feed_detail: {
  //       see_more_button_text: "See More",
  //       see_less_button_text: "See Less",
  //       like_button_text: "Like",
  //       liked_button_text: "Liked",
  //       comment_button_text: "Comment",
  //       write_a_comment_placeholder: "Write a comment…",
  //       main_comment_edit_menu_text: "Edit",
  //       main_comment_like_text: "Like",
  //       main_comment_liked_text: "Liked",
  //       main_comment_reply_text: "Reply",
  //       main_comment_delete_menu_text: "Delete",
  //       main_comment_confirmation_text:
  //         "Are you sure you want to delete this comment?",
  //       write_a_reply_placeholder: "Write a reply",
  //       single_comment_edit_menu_text: "Edit",
  //       single_comment_delete_menu_text: "Delete",
  //       single_comment_confirmation_menu_text:
  //         "Are you sure you want to delete this comment?",
  //     },
  //   },
  // };

  /* ------------------------------------------------------
------------------/ Hooks Functions /-------------------
  ------------------------------------------------------- */

  const handleContentSettingData = (val) => {
    setContentSettingData(val);
  };
  const handleJSCode = (val) => {
    setJSCode(val);
  };
  const handleCSSCode = (val) => {
    setCssCode(val);
  };
  const handleDashboardSettingData = (val) => {
    setDashboardSettingData(val);
  };
  const handleDashboardTestimonialData = (val) => {
    setDashboardTestimonialData(val);
  };
  const handleDashboardEventData = (val) => {
    setDashboardEventData(val);
  };
  const handleAdminTimeZone = (val) => {
    setAdminTimeZone(val);
  };
  const handleUserInfo = (val) => {
    setUserInfo(val);
  };
  const handleConsultantInfo = (val) => {
    setConsultantInfo(val);
  };

  const handleConsultantList = (val) => {
    setConsultantList(val);
  };
  const handleNavbarList = (val) => {
    setNavbarList(val);
  };

  const handleClientMetadata = (val) => {
    setClientMetaData(val);
  };
  const handleProjectInfo = (val) => {
    setProjectInfo(val);
    localStorage.setItem(`project_info`, JSON.stringify(val));
    //
  };
  const handleLoginInfo = (val) => {
    setLoginInfo(val);
  };
  const handleStripeKey = (val) => {
    setStripeKey(val);
  };

  const handleGraphics = (val) => {
    set_client_graphics_in_localStorage(val);
    setGraphicsData(val);
  };
  const handleClientConfiguration = (val) => {
    set_client_configuration_in_localStorage(val);
    setClientConfiguration(val);
  };

  const handleNotificationsCount = (val) => {
    setNotificationsCount(val);
  };

  const handleUnreadMessageCount = (val) => {
    setUnreadMessageCount(val);
  };

  const handleFeedSetting = (val) => {
    setFeedSetting(val);
  };

  const START_SOCKET_FORCEFULLY = (user_id) => {
    socket = io(
      socketBaseUri + `?user_id=${user_id}&role=member`,
      socketConfig
    );
  };
  const STOP_SOCKET_FORCEFULLY = (user_id) => {
    socket.disconnect();
  };

  const convertedTimeInUserTimeZoneFromNow = (dateString) => {
    return moment(
      get_date_with_user_time_zone(
        dateString,
        "YYYY-MM-DD HH:mm:ss",
        userInfo.time_zone,
        adminTimeZone
      )
    ).fromNow();
  };

  // A cache to store the converted moment date for each unique input
  const dateCache = {};
  const convertedTimeInUserTimeZoneFromNowWithCacheObj = (dateString) => {
    // Check if we already have a cached moment object for the date
    if (!dateCache[dateString]) {
      // If not cached, convert the date to the user's time zone
      dateCache[dateString] = moment(
        get_date_with_user_time_zone(
          dateString,
          "YYYY-MM-DD HH:mm:ss",
          userInfo.time_zone,
          adminTimeZone
        )
      );
    }
    // Use the cached moment object and calculate the relative time from now
    const convertedDate = dateCache[dateString];
    // Ensure the converted date is valid
    if (!convertedDate.isValid()) {
      return "-";
    }
    return convertedDate.fromNow();
  };

  const convertedTimeInUserTimeZoneFromCalender = (dateString) => {
    return moment(
      get_date_with_user_time_zone(
        dateString,
        "YYYY-MM-DD HH:mm:ss",
        userInfo.time_zone,
        adminTimeZone
      )
    ).calendar();
  };

  const getClientNavbarContent = (key, value) => {
    let label = value;
    if (clientNavbarContent && clientNavbarContent[key]) {
      label = clientNavbarContent[key];
    }
    return label;
  };

  useEffect(() => {
    if (userInfo?._id && !isConnected) {
      socket.emit("live_event_room", userInfo._id);
      setIsConnected(true);
    }
    return () => {};
  }, [userInfo, isConnected]);

  console.log(socket, "i am socket");
  const collection = {
    adminTimeZone,
    cssCode,
    jsCode,
    navbarList,
    setIsChangingCoach,
    isChangingCoach,
    consultantInfo,
    consultantList,
    userInfo,
    projectInfo,
    loginInfo,
    dashboardEventData,
    contentSettingData,
    dashboardSettingData,
    dashboardTestimonialData,
    graphicsData,
    clientConfiguration,

    notificationsCount,
    unreadMessageCount,
    stripeKey,
    feedSetting,
    handleContentSettingData,
    handleDashboardSettingData,
    handleDashboardTestimonialData,
    handleDashboardEventData,
    handleGraphics,
    handleClientConfiguration,
    handleClientMetadata,
    handleAdminTimeZone,
    convertedTimeInUserTimeZoneFromNow,
    convertedTimeInUserTimeZoneFromNowWithCacheObj,
    convertedTimeInUserTimeZoneFromCalender,
    handleUserInfo,
    handleConsultantInfo,
    handleConsultantList,
    handleNavbarList,
    setNavbarList,
    handleProjectInfo,
    handleLoginInfo,
    handleNotificationsCount,
    handleUnreadMessageCount,
    handleStripeKey,

    handleJSCode,
    handleCSSCode,
    handleFeedSetting,
    // socket instant
    socket,
    START_SOCKET_FORCEFULLY,
    STOP_SOCKET_FORCEFULLY,

    clientMetaData,
    defaultData,
    setDefaultData,
    defaulLanguagae,
    setClientNavbarContent,
    clientNavbarContent,
    setDefaultLanguage,
    isLnaguageChange,
    setIsLanguageChange,
    getClientNavbarContent,
    // community,
  };

  return (
    <CreateContentSetting.Provider value={collection}>
      {children}
    </CreateContentSetting.Provider>
  );
}
export default ContentSettingState;
